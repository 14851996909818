<template>
    <div class="order-detail__body">
        <div
            v-if="uploadedFiles.length > 0"
            class="order-detail__row"
        >
            <FilesTable :files="uploadedFiles" />
        </div>
        <div
            v-else
            class="order-detail__row"
        >
            <no-result type="files" />
        </div>
    </div>
</template>

<script>
import { mainMarkAsRead } from '@/services/files'
import { eventBus } from '@/helpers/event-bus'
// Components
import NoResult from '@/components/common/NoResult'
import FilesTable from '@/components/common/files/FilesTable.vue';

export default {
    components: {
        FilesTable,
        NoResult
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        uploadedFiles: []
    }),
    computed: {
        unreadFilesIds() {
            const unreadFiles = this.uploadedFiles.filter((file) => file.is_unread)
            return unreadFiles.map((file) => file.id)
        }
    },
    watch: {
        'unreadFilesIds.length': async function (val) {
            if (val) {
                await this.markAsReadMain()
            }
        }
    },
    async created() {
        this.uploadedFiles = [...this.order.files_main]
    },
    methods: {
        async markAsReadMain() {
            try {
                const requests = []
                this.unreadFilesIds.forEach((id) => {
                    requests.push(mainMarkAsRead(id))
                })
                await Promise.all(requests)
                setTimeout(() => {
                    [...this.unreadFilesIds].forEach((id) => {
                        const findIndex = this.uploadedFiles.findIndex((item) => item.id === id)
                        this.uploadedFiles.splice(findIndex, 1, {
                            ...this.uploadedFiles[findIndex],
                            is_unread: false
                        })
                    })
                    this.$emit('markAsReadMain')
                }, 10000);
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>

<style lang='scss'>
    .image_progress{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
</style>

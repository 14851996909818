<template>
    <div class="attachments-table__row">
        <div class="attachments-table__cell">
            <div class="loaded-file__preview">
                <span class="loaded-file__preview-format">
                    {{ fileExtension }}
                </span>
            </div>
            <span
                class="loaded-file__name"
                :class="{'attachments-table__row--new': file.is_unread}"
            >
                <p class="file__name">
                    {{ getFileName }}
                </p>
                <span
                    v-if="isFinalFile"
                    class="final-file"
                >
                    The final file
                </span>
            </span>
        </div>
        <div class="attachments-table__cell">
            <span class="loaded-file__date">
                <span
                    v-if="file.created_at"
                    style="align-items: center"
                >
                    {{ file.created_at | moment_from }} ago<br>
                    <span
                        class="loaded-file__date-short-text tooltip-zoom"
                        :data-tooltip-attribute="file.created_at | moment_short_text"
                    >
                        i
                    </span>
                </span>
            </span>
        </div>
        <!-- client files -->
        <template v-if="(file.author && file.author.type === 'Client') || !file.author">
            <div class="attachments-table__cell">
                <span class="loaded-file__author-details--name">
                    You
                </span>
            </div>
        </template>
        <!-- client files -->
        <!-- writer or admin files -->
        <template v-else>
            <div
                v-if="file.author"
                class="attachments-table__cell"
            >
                <avatar
                    :avatar-url="file.author.avatar_url"
                    :avatar-fallback="file.author.avatar_fallback"
                    :class-name="'attachments-avatar'"
                />
                <span class="loaded-file__author-details--name">
                    <span class="loaded-file__author-details--staff">
                        {{ file.author.type }}
                    </span>
                    <span v-if="file.author.name">
                        {{ file.author.name }}
                    </span>
                </span>
            </div>
            <div
                v-else
                class="attachments-table__cell"
            />
        </template>
        <!-- writer or admin files -->

        <!-- filesize -->
        <div class="attachments-table__cell">
            <span class="loaded-file__size">
                {{ Number(file.filesize) | file_size }}
            </span>
        </div>
        <!-- filesize -->

        <!-- actions -->
        <div class="attachments-table__cell attachments-table__cell_actions">
            <!-- download file -->
            <a
                class="attachments-table__action attachments-table__action_download"
                :href="file.url"
                download
                @click="getFileIdForMarsIsRead(file.id)"
            />
            <!-- download file -->

            <!-- delete file -->
            <a
                v-if="(file.author && file.author.type === 'Client') || !file.author"
                class="attachments-table__action attachments-table__action_delete"
                title="Delete File"
                @click.prevent="deleteFile"
            />
            <!-- delete file -->
        </div>
        <!-- actions -->
    </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue';
import filtersMixin from '@/mixins/filters-mixin'

export default {
    name: 'FilesTableRow',
    components: {
        Avatar
    },
    mixins: [
        filtersMixin
    ],
    props: {
        file: {
            type: Object,
            required: true
        }
    },
    computed: {
        fileExtension() {
            const data = this.file.filename.split('.')
            if (data[1]) return data[1]
            return false
        },
        isFinalFile() {
            return this.file.complete_status && this.file.complete_status === 1
        },
        getDownloadUrl() {
            return `${process.env.VUE_APP_CDN_URL}/file/`;
        },
        getFileName() {
            const [name, extension] = this.file.filename.split('.')
            return `${name.slice(0, 25)}.${extension}`
        }
    },
    methods: {
        deleteFile() {
            this.$emit('deleteFile', { ...this.file })
        },
        getFileIdForMarsIsRead(id) {
            this.$emit('checkMarkAsRead', id)
        }
    }
}
</script>

<style lang="scss">
    .file__name{
        display: flex;
        align-items: center;
    }
</style>

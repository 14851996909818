<template>
    <div class="attachments-table">
        <files-table-header />
        <div class="attachments-table__body">
            <transition-group name="delete">
                <files-table-row
                    v-for="file in files"
                    :key="file.id || file.created_at"
                    :file="file"
                    @deleteFile="deleteFile"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import FilesTableHeader from '@/components/common/files/FilesTableHeader.vue';
import FilesTableRow from '@/components/common/files/FilesTableRow.vue';

export default {
    components: {
        FilesTableHeader,
        FilesTableRow
    },
    props: {
        files: {
            type: Array,
            required: true
        }
    },
    methods: {
        deleteFile(file) {
            this.$emit('deleteFile', file)
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/common/pages/user-account/attachments";
</style>
